<!--
1上2下
-->
<template>
  <div class="cube-1">
    <template v-if="!canEdit">
      <div class="cube-1-content">
        <el-row :gutter="12">
          <p style="text-align: center;padding-top: 10px;">页面信息</p>
          <p  style="text-align: center;font-size: 14px;color: red;padding-top: 5px;">请先保存页面信息再添加组件</p>
        </el-row>
      </div>
    </template>
    <template v-else>
      <div class="cube-1-edit">
        <p class="c-e-header">页面信息</p>
        <div class="cube-content" v-for="(classifyData, i) in cptData.data" :key="'cube-1' + i">
          <el-form
            :rules="rules"
            ref="form"
            :model="classifyData"
            class="vendorFrom"
            label-position="right"
            label-width="100px"
          >
            <el-form-item label="页面标题" prop="title">
              <el-input v-model="classifyData.title" placeholder="输入自定义名称"></el-input>
            </el-form-item>
            <el-form-item label="分享文案" prop="abstractContent">
              <el-input v-model="classifyData.abstractContent" placeholder="输入自定义名称"></el-input>
            </el-form-item>
            <el-form-item label="分享图片" prop="image">
              <div class="cube-img-block">
                  <bm-upload v-model="classifyData.image" type="system"></bm-upload>
              </div>
              <div>1280px * 794px</div>
            </el-form-item>
            <el-form-item v-loading="loading">
              <el-button type="primary" @click="save(i)">保存</el-button>
              <!-- <el-button type="danger" @click="remove(i)">删除</el-button> -->
            </el-form-item>
          </el-form>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { log } from '@/util/log'
import activitySelect from './__com__/activitySelect'

export default {
  name: 'imgUrl',
  inject: ['addidtocomponent','pageInfo'],
  components: { activitySelect },
  props: {
    cptData: {
      type: Object,
      default: {
        componentName: 'classify',
        selectStatus: false,
        data: [
          {
            hideStatus: 0,
          }
        ]
      },
      require: true
    },
    canEdit: {
      type: Boolean,
      default: false,
      require: false,
      comment: '是否编辑状态， true是，会在装修页面右侧调用'
    }
  },
  data() {
    return {
      loading: false,
      defaultImg: require('@/asset/logo.png'),
      areaId: this.$store.getters['malls/getCurrentEditAreaId'],
      rules: {
        image: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
             if (!this.cptData.data[0].image) {
                return callback(new Error('选择图片'))
              }
              callback()
            }
          }
        ],
        title: [{ required: true, message: '请输入页面分享标题', trigger: 'change' }],
        abstractContent: [{ required: false, message: '输入名字', trigger: 'change' }],
      },
      model: {
        id: '',
        abstractContent: '',
        image: '',
        pageContent: '',
        title: ''
      }
    }
  },
  created() {
    if (!this.cptData.data.length) {
      this.cptData.data.push({ ...this.model })
    }
  },
  methods: {
    initRadio(i) {
      if (this.cptData.data[0].imgNode[i].type == 1) {
        this.cptData.data[0].imgNode[i].target = ''
      }
    },
    save() {
      this.$refs['form'][0].validate(valid => {
        if (valid) {
          this.$emit('page-info',this.cptData.data[0])
        }
      })
    },
    async remove(index) {
      if (!this.cptData.data[0].id) {
        this.$message.info('无数据，无须删除')
        return
      }
      let res = await this.$confirm('此操作将永久删除当前编辑数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (res == 'confirm') {
        this.loading = true
        this.$http
          .delete('boom-center-admin-service/sysAdmin/decorationMagicCube/' + this.cptData.data[0].id)
          .then(() => {
            this.addidtocomponent({ id: '', activeStatus: '' })
            this.$message.success('移除成功')
          })
          .catch(err => {
            this.$message.error(err)
          })
          .finally(() => {
            setTimeout(() => {
              this.loading = false
            }, 200)
          })
      } else {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import "./com.less";
.cube-1 {
  .cube-1-content {
    min-height: 78px;
    padding: 3px 5px;
    .cube-item {
      padding: 3px;
      img {
        width: 100%;
        height: 68px;
      }
    }
  }
  .cube-1-edit {
    padding: 25px;
    background-color: @color-background;
    .c-e-header {
      margin-bottom: 20px;
      font-weight: 700;
    }
    .cube-content {
      .cube-img-block {
        width: 60%;
        display: inline-block;
        .cube-img-item {
          display: inline-block;
          // width: 50%;
          position: relative;
          & > span {
            background-color: rgba(0, 0, 0, 0.1);
            position: absolute;
            .radius(6px);
            display: inline-block;
            padding: 0 5px;
            top: 0;
            left: 0;
            z-index: 10;
          }
        }
      }
    }
  }
}
</style>
