<template lang="html">
  <el-dialog title="商品列表" :visible.sync="dialogTableVisible" @close="cancelModal" width="90vw">
    <el-card class="searchContainer">
      <el-form inline>
        <el-form-item label="商品名称">
          <el-input
            v-model="filter.productName"
            placeholder="请输入商品名称"
            @keyup.enter.native="handleSearch"
          ></el-input>
        </el-form-item>

        <el-form-item label="商家ID">
          <el-input
            v-model="filter.businessId"
            placeholder="请输入商家ID"
            @keyup.enter.native="handleSearch"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-table
      :data="list"
      ref="multipleTable"
      fit
      center
      v-loading="loading"
      @selection-change="handleSelectionChange"
      :row-key="getRowKeys"
    >
      <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
      <el-table-column prop="id" width="94" label="ID" align="center"></el-table-column>

      <el-table-column prop="id" label="商品主图" align="center" width="70">
        <template slot-scope="scope">
          <img :src="scope.row.productImageUrl | thumbMedium" style="width: 50px; height: 50px" />
        </template>
      </el-table-column>

      <el-table-column prop="productName" label="商品信息" align="center" width="200">
        <template slot-scope="scope">
          <div style="display: flex; justify-content: center">
            <div class="productName">{{ scope.row.productName }}</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="商家信息" align="center">
        <template slot-scope="scope">
          <div class="vendorInfo" @click="handleClickBusiness(scope.row.businessId)">
            <div>名称: {{ scope.row.businessTitle }}</div>
            <div>ID：{{ scope.row.businessId }}</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="areaId" label="地区" align="center" width="50">
        <template slot-scope="scope">
          <span class="title">{{ scope.row.areaIds | formatManyAreas }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="id" label="sku信息" align="center" width="430">
        <template slot-scope="scope">
          <div class="skuContainer" v-for="(i, n) in scope.row.productSkuList" :key="n">
            <div class="info">
              <div class="label">当前库存：</div>
              <div class="value">{{ i.currentStock }}</div>
            </div>
            <div class="info">
              <div class="label">已售：</div>
              <div class="value">{{ i.saleNum }}</div>
            </div>
            <div class="info">
              <div class="label">售价：</div>
              <div class="value">{{ i.salePrice }}</div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="id" label="截止时间" align="center" width="200">
        <template slot-scope="scope">
          <div>上架时间:{{ scope.row.saleTimeStart | formatTime }}</div>
          <div>下架时间:{{ scope.row.saleTimeEnd | formatTime }}</div>
          <div>过期时间:{{ scope.row.writeOffTimeEnd | formatTime }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="上架状态" align="center" width="50">
        <template slot-scope="scope">
          <el-switch
            :value="scope.row.switchPublish"
            @change="onStatusChange(scope.row)"
            :inactive-value="0"
            :active-value="1"
          ></el-switch>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-pagination
        background
        :total="page.total"
        :page-sizes="sizeList"
        :page-size="page.size"
        :current-page="page.page"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    </div>
    <div class="confrim-btns">
      <el-button type="primary" @click="comfrimSelectData">确定</el-button>
      <el-button @click="cancelModal">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import tableMixin from '@/mixin/table'
export default {
  mixins: [tableMixin],
  props: {
    dialogTableVisible: {
      type: Boolean,
      default: false
    },
    currentData: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      lits: [],
      filter: {
        productName: '',
        businessName: '',
        secKill:0,
        shelfStatus:1
      },
      page: {
        total: 0,
        page: 1,
        size: 5
      },
      showProductList: true,
      multipleSelection: [],
      getRowKeys(row) {
        return row.id;
      },
      selectData: []
    };
  },
  mounted() {
    this.getData()
  },
  watch: {
    dialogTableVisible: {
      handler(val) {
        this.$nextTick(() => {
          this.list.forEach(item => {
            this.currentData.forEach(row => {
              if (item.id == row.id) {
                this.$refs.multipleTable.toggleRowSelection(item);
              }
            });
          })
        })
      }
    }
  },
  methods: {
    cancelModal() {
      this.$emit('cancel-modal')
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    getList() {
      return this.$http.get('boom-center-product-service/sysAdmin/product/page', {
        params: { ...this.filter, ...this.page }, 
        headers: {
          ['Bm-Area-Id']: localStorage.getItem('currentAreaId') || '-1'
        }
      })
    },
    getData() {
      this.loading = true
      this.getList()
        .then(res => {
          this.list = res.list
          this.page.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    comfrimSelectData() {
      this.$emit('comfrim-data', this.multipleSelection)
      this.$emit('cancel-modal')
    }
  },
};
</script>
<style lang="less" scoped>
.skuContainer {
  display: flex;
  .info {
    flex-grow: 1;
    display: flex;
    margin-right: 5px;
  }
}
.footer {
  margin-top: 15px;
}

/deep/.searchContainer .el-card__body {
  padding: 0;
}

.searchContainer {
  margin: 0;
}

.confrim-btns {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 15px 0;
}
</style>
