<!--
1上2下
-->
<template>
  <div class="cube-1">
    <template v-if="!canEdit">
      <div class="cube-1-content" v-if="cptData.data[0].componentData.sortType == 0">
        <el-row :gutter="10">
          <!-- <div v-html="cptData.data[0].textIntro" :height="300"></div> -->
          <!-- 商品组件 -->
          <div
            class="product-horizontal"
            v-for="item in cptData.data[0].componentData.productList"
            :key="item.id"
          >
            <img :src="item.productImageUrl" alt />
            <div class="product-r">
              <p>{{ item.productName }}</p>
              <div>已售:{{ item.productSkuList.saleCount }}</div>
              <span>
                现价:{{ item.productSkuList.salePrice }}
                <s>原价:{{ item.productSkuList.originalPrice }}</s>
              </span>
            </div>
          </div>
        </el-row>
      </div>
      <div class="cube-1-content" v-else>
        <el-row :gutter="10">
          <!-- <div v-html="cptData.data[0].textIntro" :height="300"></div> -->
          <!-- 商品组件 -->
          <div class="product-vertical-wrap">
            <div
              class="product-vertical"
              v-for="item in cptData.data[0].componentData.productList"
              :key="item.id"
            >
              <img :src="item.productImageUrl" alt />
              <div class="product-r">
                <p>{{ item.productName }}</p>
                <div>已售:{{ item.productSkuList.saleCount }}</div>
                <span>
                  现价:{{ item.productSkuList.salePrice }}
                  <s>原价:{{ item.productSkuList.originalPrice }}</s>
                </span>
              </div>
            </div>
          </div>
        </el-row>
      </div>
    </template>
    <template v-else>
      <div class="cube-1-edit">
        <p class="c-e-header">内容组件-商品列表</p>
        <div class="cube-content" v-for="(classifyData, i) in cptData.data" :key="'cube-1' + i">
          <el-form
            :rules="rules"
            ref="form"
            :model="classifyData"
            class="vendorFrom"
            label-position="right"
            label-width="100px"
          >
            <el-form-item label="状态">
              <el-switch v-model="classifyData.hide" :active-value="false" :inactive-value="true"></el-switch>
            </el-form-item>
            <el-form-item label="排序规则" prop="sortType">
              <el-select v-model="classifyData.componentData.sortType" placeholder="请选择">
                <el-option
                  v-for="item in sortRule"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品ID" prop="productIds">
              <el-input
                :value="classifyData.componentData.productList.map(i => i.id).join(',')"
                type="textarea"
                :row="3"
                disabled
              ></el-input>
              <el-button
                size="small"
                type="primary"
                block
                class="mt-15"
                @click="showProductListModal"
              >添加商品</el-button>
            </el-form-item>
            <el-form-item label="是否过滤售罄" prop="textIntro">
              <el-radio-group v-model="classifyData.componentData.isFilterSoldOut">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-loading="loading">
              <!-- <el-button type="primary" @click="list">列表</el-button> -->
              <el-button type="primary" @click="save(i)">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </template>
    <productList
      :dialogTableVisible="productListModal"
      @comfrim-data="getSelectData"
      @cancel-modal="cancelProductListModal"
      :currentData="cptData.data[0].componentData.productList"
    ></productList>
  </div>
</template>
<script>
import { log } from '@/util/log'
import activitySelect from './__com__/activitySelect'
import productList from "./__com__/productList.vue"

export default {
  name: 'productListComponent',
  inject: ['addidtocomponent', 'savaTinyPage'],
  components: { activitySelect, productList },
  props: {
    cptData: {
      type: Object,
      default: {
        componentName: 'classify',
        selectStatus: false,
        data: [
          {
            hide: 0
          }
        ]
      },
      require: true
    },
    canEdit: {
      type: Boolean,
      default: false,
      require: false,
      comment: '是否编辑状态， true是，会在装修页面右侧调用'
    }
  },
  data() {
    return {
      loading: false,
      defaultImg: require('@/asset/logo.png'),
      areaId: this.$store.getters['malls/getCurrentEditAreaId'],
      productListModal: false,
      rules: {
        imgs: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              for (let i of this.cptData.data[0].imgNode) {
                if (!i.imagePath) {
                  return callback(new Error('选择图片'))
                }
              }
              callback()
            }
          }
        ],
        productIds: [{
          required: true, message: '输入商品ID', trigger: 'change', validator: (rule, value, callback) => {
            callback()
          }
        }],
        sortType: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              callback()
            }
          }
        ],
        target: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              for (let i of this.cptData.data[0].imgNode) {
                if (!i.target) {
                  return callback(new Error('跳转活动或链接必填'))
                }
              }
              callback()
            }
          }
        ]
      },
      sortRule: [
        {
          label: '横向排列',
          value: 0
        },
        {
          label: '纵向排列',
          value: 1
        }
      ],
      model: {
        id: '',
        componentType: 'PRODUCT',
        componentName: '',
        componentData: {
          sortType: 0,
          productList: [
            {
              id: '',
              productName: '',
              productImageUrl: '',
              productSkuList: {}
            }
          ],
          isFilterSoldOut: false
        },
        hide: 0,
      },
      currentProductList: []
    }
  },
  created() {
    if (!this.cptData.data.length) {
      this.cptData.data.push({ ...this.model })
    }
  },
  methods: {
    showProductListModal() {
      this.productListModal = true
    },
    cancelProductListModal() {
      this.productListModal = false
    },
    getSelectData(data) {
      this.cptData.data[0].componentData.productList = data.map(i => {
        return {
          id: i.id,
          productName: i.productName,
          productImageUrl: i.productImageUrl,
          productSkuList: i.productSkuList[0]
        }
      })
    },
    initRadio(i) {
      if (this.cptData.data[0].imgNode[i].type == 1) {
        this.cptData.data[0].imgNode[i].target = ''
      }
    },
    save() {
      this.$refs['form'][0].validate(valid => {
        if (valid) {
          let tag = ''
          if (!this.cptData.data[0].id) {
            tag = 'postMagicCube'
          } else {
            tag = 'putMagicCube'
          }
          this.loading = true
          Promise.resolve()
            .then(() => {
              if (tag === 'postMagicCube') {
                return this.$http.post('boom-center-admin-service/sysAdmin/decorationMagicCube', this.cptData.data[0], {
                  headers: {
                    ['Bm-Area-Id']: this.areaId
                  }
                })
              }
              if (tag === 'putMagicCube') {
                return this.$http.put(`boom-center-admin-service/sysAdmin/micro/page/${this.cptData.data[0].id}`, this.cptData.data[0], {
                  headers: {
                    ['Bm-Area-Id']: this.areaId
                  }
                })
              }
            })
            .then(res => {
              this.savaTinyPage()
              this.$message.success('保存成功')
            })
            .catch(err => {
              this.$message.error(err)
            })
            .finally(() => {
              setTimeout(() => {
                this.loading = false
              }, 200)
            })
        }
      })
    },
    async remove(index) {
      if (!this.cptData.data[0].id) {
        this.$message.info('无数据，无须删除')
        return
      }
      let res = await this.$confirm('此操作将永久删除当前编辑数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (res == 'confirm') {
        this.loading = true
        this.$http
          .delete('boom-center-admin-service/sysAdmin/decorationMagicCube/' + this.cptData.data[0].id)
          .then(() => {
            this.addidtocomponent({ id: '', activeStatus: '' })
            this.$message.success('移除成功')
          })
          .catch(err => {
            this.$message.error(err)
          })
          .finally(() => {
            setTimeout(() => {
              this.loading = false
            }, 200)
          })
      } else {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import "./com.less";

.mt-15 {
  margin-top: 15px;
}
.cube-1 {
  .cube-1-content {
    min-height: 78px;
    padding: 3px 5px;
    .cube-item {
      padding: 3px;
      img {
        width: 100%;
        height: 68px;
      }
    }
  }
  .cube-1-edit {
    padding: 25px;
    background-color: @color-background;
    .c-e-header {
      margin-bottom: 20px;
      font-weight: 700;
    }
    .cube-content {
      .cube-img-block {
        width: 60%;
        display: inline-block;
        .cube-img-item {
          display: inline-block;
          // width: 50%;
          position: relative;
          & > span {
            background-color: rgba(0, 0, 0, 0.1);
            position: absolute;
            .radius(6px);
            display: inline-block;
            padding: 0 5px;
            top: 0;
            left: 0;
            z-index: 10;
          }
        }
      }
    }
  }
}
.product-horizontal {
  height: 78px;
  display: flex;
  align-items: center;
  margin: 10px;
  img {
    width: 120px;
    height: 78px;
  }
  .product-r {
    width: 230px;
    padding-left: 20px;
    height: 78px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      font-size: 14px;
      font-weight: 700;
      color: #333333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    div {
      font-size: 14px;
      color: #999999;
    }
    span {
      color: #e7141a;
      font-weight: 700;
      font-size: 14px;
      s {
        padding-left: 10px;
        color: #ccc;
        font-size: 12px;
      }
    }
  }
}
.product-vertical-wrap {
  display: flex;
  flex-wrap: wrap;
}
.product-vertical {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 20px;
  margin-bottom: 20px;
  &:nth-child(2n + 2) {
    padding-right: 0;
  }
  img {
    width: 100%;
    height: 150px;
    margin-bottom: 10px;
  }
  .product-r {
    width: 100%;
    p {
      font-size: 14px;
      font-weight: 700;
      color: #333333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    div {
      font-size: 14px;
      color: #999999;
    }
    span {
      color: #e7141a;
      font-weight: 700;
      font-size: 14px;
      s {
        padding-left: 10px;
        color: #ccc;
        font-size: 12px;
      }
    }
  }
}
</style>
