<template>
  <div class="cube-1">
    <template v-if="!canEdit">
      <div class="cube-1-content">
        <el-row :gutter="10">
          <template v-if="cptData.data[0] && cptData.data[0].componentData">
            <el-col :span="24" class="cube-item">
              <img :src="cptData.data[0].componentData.imageUrl" alt height="200"/>
            </el-col>
          </template>
        </el-row>
      </div>
    </template>
    <template v-else>
      <div class="cube-1-edit">
        <p class="c-e-header">内容组件-图片组件</p>
        <div class="cube-content" v-for="(classifyData, i) in cptData.data" :key="'cube-1' + i">
          <el-form
            ref="form"
            :rules="rules"
            :model="classifyData"
            class="vendorFrom"
            label-position="right"
            label-width="100px"
          >
            <el-form-item label="状态">
              <el-switch v-model="classifyData.hide" :active-value="false" :inactive-value="true"></el-switch>
            </el-form-item>
            <el-form-item label="组件图片" prop="imageUrl">
              <div class="cube-img-block">
                <div class="cube-img-item">
                  <bm-upload v-model="classifyData.componentData.imageUrl" type="system"></bm-upload>
                </div>
              </div>
              <div>1280px * 794px</div>
            </el-form-item>
            <el-form-item label="组件名称" prop="componentName">
              <el-input v-model="classifyData.componentName" placeholder="输入自定义名称"></el-input>
            </el-form-item>
            <el-form-item v-loading="loading">
              <!-- <el-button type="primary" @click="list">列表</el-button> -->
              <el-button type="primary" @click="save(i)">保存</el-button>
              <!-- <el-button type="danger" @click="remove(i)">删除</el-button> -->
            </el-form-item>
          </el-form>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { log } from '@/util/log'
import activitySelect from './__com__/activitySelect'

export default {
  name: 'imgUrl',
  inject: ['addidtocomponent', 'savaTinyPage'],
  components: { activitySelect },
  props: {
    cptData: {
      type: Object,
      default: {
        componentName: 'classify',
        selectStatus: false,
        data: [
          {
            hide: 0
          }
        ]
      },
      require: true
    },
    canEdit: {
      type: Boolean,
      default: false,
      require: false,
      comment: '是否编辑状态， true是，会在装修页面右侧调用'
    }
  },
  data() {
    return {
      loading: false,
      defaultImg: require('@/asset/logo.png'),
      areaId: this.$store.getters['malls/getCurrentEditAreaId'],
      rules: {
        imageUrl: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              if (!this.cptData.data[0].componentData.imageUrl) {
                return callback(new Error('选择图片'))
              }
              callback()
            }
          }
        ],
        componentName: [{ required: true, message: '输入名字', trigger: 'change' }]
      },
      model: {
        id: '',
        componentType: 'IMAGE',
        componentName: '',
        componentData: { imageUrl: '' },
        hide: 0,
      }
    }
  },
  created() {
    if (!this.cptData.data.length) {
      this.cptData.data.push({ ...this.model })
    }
  },
  methods: {
    // initRadio(i) {
    //   if (this.cptData.data[0].imgNode[i].type == 1) {
    //     this.cptData.data[0].imgNode[i].target = ''
    //   }
    // },
    save() {
      this.$refs['form'][0].validate(valid => {
        if (valid) {
          let tag = ''
          if (!this.cptData.data[0].id) {
            tag = 'postMagicCube'
          } else {
            tag = 'putMagicCube'
          }
          this.loading = true
          Promise.resolve()
            .then(() => {
              if (tag === 'postMagicCube') {
                return this.$http.post(`boom-center-admin-service/sysAdmin/micro/page/${this.cptData.data[0].id}`, this.cptData.data[0], {
                  headers: {
                    ['Bm-Area-Id']: this.areaId
                  }
                })
              }
              if (tag === 'putMagicCube') {
                return this.$http.put(`boom-center-admin-service/sysAdmin/micro/page/${this.cptData.data[0].id}`, this.cptData.data[0], {
                  headers: {
                    ['Bm-Area-Id']: this.areaId
                  }
                })
              }
            })
            .then(res => {
              this.savaTinyPage()
              this.$message.success('保存成功')
            })
            .catch(err => {
              this.$message.error(err)
            })
            .finally(() => {
              setTimeout(() => {
                this.loading = false
              }, 200)
            })
        }
      })
    },
    async remove(index) {
      if (!this.cptData.data[0].id) {
        this.$message.info('无数据，无须删除')
        return
      }
      let res = await this.$confirm('此操作将永久删除当前编辑数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (res == 'confirm') {
        this.loading = true
        this.$http
          .delete('boom-center-admin-service/sysAdmin/decorationMagicCube/' + this.cptData.data[0].id)
          .then(() => {
            this.addidtocomponent({ id: '', activeStatus: '' })
            this.$message.success('移除成功')
          })
          .catch(err => {
            this.$message.error(err)
          })
          .finally(() => {
            setTimeout(() => {
              this.loading = false
            }, 200)
          })
      } else {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import "./com.less";
.cube-1 {
  .cube-1-content {
    min-height: 78px;
    padding: 3px 5px;
    .cube-item {
      padding: 3px;
      img {
        width: 100%;
        height: 98px;
      }
    }
  }
  .cube-1-edit {
    padding: 25px;
    background-color: @color-background;
    .c-e-header {
      margin-bottom: 20px;
      font-weight: 700;
    }
    .cube-content {
      .cube-img-block {
        width: 60%;
        display: inline-block;
        .cube-img-item {
          display: inline-block;
          // width: 50%;
          position: relative;
          & > span {
            background-color: rgba(0, 0, 0, 0.1);
            position: absolute;
            .radius(6px);
            display: inline-block;
            padding: 0 5px;
            top: 0;
            left: 0;
            z-index: 10;
          }
        }
      }
    }
  }
}
</style>
